// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as fasFaBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faAngleRight as fasFaAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faArrowRight as fasFaArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faEnvelope as fasFaEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faPhoneAlt as fasFaPhoneAlt } from "@fortawesome/pro-solid-svg-icons/faPhoneAlt";


import { faFacebook     as fabFaFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram    as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter      as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube      as fabFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faLinkedin     as fabFaLinkedin  } from "@fortawesome/free-brands-svg-icons/faLinkedin";

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaTimes, fasFaAngleRight, fasFaEnvelope, fasFaPhoneAlt, fasFaArrowRight);

/**
 * Add brands icons
 */
library.add(fabFaFacebook, fabFaInstagram, fabFaTwitter, fabFaYoutube, fabFaLinkedin);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
